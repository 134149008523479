<script setup>
import { computed, ref, watch } from "vue"
import { useRoute } from 'vue-router'
import useFindingsDetailsStore from "@/stores/findings"
import useMapaStore from "@/stores/mapa"
import useGeneralStore from "@/stores/general"
import { postAlteracao } from '@/services/exameDetails'
import snackbarNotification from "../snackbarNotification.vue"
import ModalDelete from '../modal/modal-delete/ModelDelete.vue'
import ModalEdit from '../modal/modal-edit/ModalEdit.vue'

const route = useRoute()
const storeFindings = useFindingsDetailsStore()
const storeMapa = useMapaStore()
const storeGeneral = useGeneralStore()
const isTeeth = ref(true)
const mostrarOrdenacao = ref(false)

const userId = localStorage.getItem("userId")
const examId = route.params.id_exam

const idFinding = ref(null)

const orderTeeth = [
  18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28,
  38, 37, 36, 35, 34, 33, 32, 31, 41, 42, 43, 44, 45, 46, 47, 48
]

const dataAlt = ref({
  id_alteracao: null,
  num_dente: null,
  id_exam: examId,
  user: userId,
})

const dentalMap = [
  { alt: 'D11', description: 'Dente 11' },
  { alt: 'D12', description: 'Dente 12' },
  { alt: 'D13', description: 'Dente 13' },
  { alt: 'D14', description: 'Dente 14' },
  { alt: 'D15', description: 'Dente 15' },
  { alt: 'D16', description: 'Dente 16' },
  { alt: 'D17', description: 'Dente 17' },
  { alt: 'D18', description: 'Dente 18' },
  { alt: 'D21', description: 'Dente 21' },
  { alt: 'D22', description: 'Dente 22' },
  { alt: 'D23', description: 'Dente 23' },
  { alt: 'D24', description: 'Dente 24' },
  { alt: 'D25', description: 'Dente 25' },
  { alt: 'D26', description: 'Dente 26' },
  { alt: 'D27', description: 'Dente 27' },
  { alt: 'D28', description: 'Dente 28' },
  { alt: 'D31', description: 'Dente 31' },
  { alt: 'D32', description: 'Dente 32' },
  { alt: 'D33', description: 'Dente 33' },
  { alt: 'D34', description: 'Dente 34' },
  { alt: 'D35', description: 'Dente 35' },
  { alt: 'D36', description: 'Dente 36' },
  { alt: 'D37', description: 'Dente 37' },
  { alt: 'D38', description: 'Dente 38' },
  { alt: 'D41', description: 'Dente 41' },
  { alt: 'D42', description: 'Dente 42' },
  { alt: 'D43', description: 'Dente 43' },
  { alt: 'D44', description: 'Dente 44' },
  { alt: 'D45', description: 'Dente 45' },
  { alt: 'D46', description: 'Dente 46' },
  { alt: 'D47', description: 'Dente 47' },
  { alt: 'D48', description: 'Dente 48' },
]

const inputValue = ref('')

const snackbarRef = ref(null)

const teethOptions = ref([])

const isTeethSelect = ref(true)

const setTeethOptions = () => {
  const inputAlt = inputValue.value.trim().toUpperCase()
  teethOptions.value = dentalMap.filter(item => item.alt === inputAlt)
}
 
const altValue = computed(() => {
  const regex = /\//
  if (regex.test(inputValue.value)) {
    const parts = inputValue.value.split('/')
    return parts.length > 1 ? parts[1].trim() : ''  
  }
  else {
    return inputValue.value
  }
})

const altOptions = ref([])

const setAltOptions = () => {
  const atalho = altValue.value.trim().toUpperCase()
  altOptions.value = storeMapa.mapaData.filter(item => item.atalho === atalho)
}

const teethSelect = (event, num_dente) => {
  inputValue.value = event.target.innerText + '/ '
  dataAlt.value.num_dente = +num_dente.slice(1)
  teethOptions.value = ''
}

const detailSelect = (event, id_alteracao) => {
  if (isTeethSelect.value || dataAlt.value.num_dente) {
    const parts = inputValue.value.split('/')
    const firstItem =  parts.length > 1 ? parts[0] + '/' : inputValue.value
    inputValue.value = firstItem + ' ' + event.target.innerText
  }
  else {
    inputValue.value = event.target.innerText
  }
  dataAlt.value.id_alteracao = id_alteracao
  altOptions.value = ''
}

const selectItem = () => {
  if (isTeethSelect.value)
    document.getElementById('teeth-list').click()
  else 
    document.getElementById('alt-list').click()
}

const resetDataAlt = () => {
  dataAlt.value.id_alteracao = null
  dataAlt.value.num_dente = null
}

const deleteFinding = (id) => {
  idFinding.value = id
  storeGeneral.toggleModalDelete(true)
}

const deleteConfirmEmit = (value) => {
  if (value) {
    snackbarRef.value.showSuccess("Achado removido com sucesso!")
  }
}

const sendChange = (event) => {
  postAlteracao(dataAlt.value)
    .then(
      snackbarRef.value.showSuccess("Achado cadastrado com sucesso!"),
      storeFindings.fetchFindings(examId)
    )
    .catch(error => {
      console.log('Error: ', error)
    })
  storeFindings.fetchFindings(examId)
  inputValue.value = ''
  event.target.blur()
  resetDataAlt()
}

watch(inputValue, (value) => {
  const regex = /^d\d{2}/i
  if (regex.test(value)) {
    setTeethOptions()
    isTeethSelect.value = true
  }
  else {
    setAltOptions()
    isTeethSelect.value = false
  }
})
</script>

<template>
  <div class="cmp-found-details">
    <snackbarNotification ref="snackbarRef" />
    <ModalDelete 
      v-if="storeGeneral.isModalDelete" 
      :id-finding="idFinding"
      @deleteConfirm="deleteConfirmEmit"
    />
    <ModalEdit v-if="storeGeneral.isModalEdit" />
    <v-row>
      <v-col cols="8">
        <h3 class="cmp-found-details__title">Detalhamento dos achados</h3>
      </v-col>
      
      <v-col 
        cols="4"
        class="d-flex justify-end align-center"
        v-if="mostrarOrdenacao"
      >
        <span class="cmp-found-detais__order-title">Ordenar por:</span>
        <button 
          class="cmp-found-details__button"
          :class="{ active: isTeeth }"
        >Dente</button>
        <button class="cmp-found-details__button d-none">Achado</button>
      </v-col>

      <v-col class="cmp-found-details__input-wrap" cols="12">
        <input 
          v-model="inputValue"
          type="text" 
          class="cmp-found-details__input"
          placeholder="+ Incluir novo achado"
          @keydown.enter="sendChange"
          @keyup.arrow-down="selectItem"
        >
        <ul
          v-show="teethOptions.length"
          class="cmp-found-details__list-teeth"
        >
          <li
            v-for="(option, i) in teethOptions" 
            :key="i"
            @click="teethSelect($event, option.alt)"
            id="teeth-list"
          >
            {{ option.description }}
          </li>
        </ul>
        <ul
          v-show="altOptions.length"
          class="cmp-found-details__list-details"
        >
          <li
            v-for="(option, i) in altOptions" 
            :key="i"
            @click="detailSelect($event, option.id_alteracao)"
            id="alt-list"
          >
            {{ option.nm_alteracao }}
          </li>
        </ul>
        <ul class="cmp-found-details__items">
          <li v-for="(count, name) in storeFindings.anatomicos" :key="name" class="cmp-found-details__item-anatomico">
            ({{ count }}) {{ name }}
            <div class="cmp-found-details__actions">
              <v-icon 
                name="ri-pencil-fill" 
                scale="1"
                class="cmp-found-details__actions--icon"
                @click="storeGeneral.toggleModalEdit(true)"
              />
              <v-icon 
                name="hi-x" 
                scale="1"
                class="cmp-found-details__actions--icon"
                @click="storeGeneral.toggleModalDelete(true)"
              />
            </div>
          </li>
        </ul>
        <!-- lista de achados dentais na ordem papaiz -->
        <div 
          v-for="i in orderTeeth"
          :key="i"
          class="cmp-found-details__items"
        >
          <div
            v-for="(items, dente) in storeFindings.dentais"
            :key="dente"
          >
            <div 
              v-if="i === parseInt(dente)" 
              class="cmp-found-details__item"
            >
              <span class="cmp-found-details__dente-title">Dente {{ i }}:</span>
              <div 
                v-for="item in items"
                :key="item"
              >
                <span 
                  class="cmp-found-details__item-found"
                >
                  {{ item.nm_alteracao }}
                  <v-icon 
                    name="hi-x" 
                    scale=".9"
                    class="cmp-found-details__item-found--icon"
                    @click="deleteFinding(item.pk)"
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<style lang="scss" scoped>
  @import '_found-details.scss'
</style>
